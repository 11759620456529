@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,700");

body {
  font-family: Roboto, sans-serif;
  margin: 0;
  background-color: #b0e0b2;
  max-width: 1700px;
  margin: auto;
  text-align: center;

}
.mainHeading{
  margin: 50px;
  color: #2d4b2e;
}
.card {
  background: #fff;
  border-radius: 25px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
  max-width: 400px;
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 10px;
  text-align: left;
}
.overlayCard {
  background: #fff;
  border-radius: 25px;
  width: 400px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 10px;
  text-align: left;
  justify-content: center;
  box-shadow: 5px 5px 5px rgba(255, 255, 255, 0),
    -5px -5px 5px rgba(255, 255, 255, 0);
}


.overlayCard .portada {
  width: 100%;
  height: 80px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0;
  background-position: bottom center;
  background-size: cover;
}

.overlayCard .card-text {
  display: flex;
  flex-direction: column;
}


.overlayCard .img-avatar {
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 50%;
  border: 6px solid white;
  background-color: #f0f0f0;
  top: 40px;
  left: 40px;
}

.overlayCard .avatar {
  top: 5px;
  left: 10px;
}

.card h2 {
  margin: 0;
}

.card .title {
  padding: 1rem;
  text-align: right;
  color: #404040;
  font-weight: bold;
  font-size: 12px;
}

.card .desc {
  padding: 0.5rem;
  font-size: 12px;
}

.card .actions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 0.5rem 1rem;
}

.card svg {
  width: 85px;
  height: 85px;
  margin: 0 auto;
}

.img-avatar {
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 50%;
  border: 6px solid white;
  background-color: #f0f0f0;
  top: 15px;
  left: 40px;
}


.avatar {
  position: relative;
  top: 5px;
  left: 5px;
  width: 50px;
  height: 50px;
}

.card-text {
  display: flex;
}

.title-total {
  padding: 1.5em 1.5em 1.5em 45px;
}

path {
  fill: white;
}

.img-portada {
  width: 100%;
}

.portada {
  width: 80px;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-position: bottom center;
  background-size: cover;
}

.cardWrap {
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  justify-content: center;
}

.card.selected {
  background-color: rgb(244, 255, 199);
  /* Change to the desired background color */
}









ul {
  padding: 0;
}

.wrapper {
  margin: 20px;
  margin-bottom: 50px;
  text-align: center;
  color: #fff;
}


.pager__item {
  display: inline-block;
  vertical-align: top;
  font-size: rem(18px);
  margin: 0 2px;
  padding: 3px;
  border-radius: 4px;
}

.pager__item.active {
  background-color: #f0c040;
  font-weight: bolder;
  color: #fff;
  text-decoration: none;
}

.pager__link {
  position: relative;
  display: block;
  text-align: center;
  width: rem(42px);
  height: rem(42px);
  line-height: rem(42px);
  margin-left: -1px;
  color: #fff;
  text-decoration: none;
  transition: .3s;
}

.pager__item:focus,
.pager__item:active {
  background-color: #f0c040;
  border-color: #f0c040;
  font-weight: bolder;
  color: #fff;
  text-decoration: none;
}

.pager__item:hover {
  font-weight: bolder;
  color: #fff;
  text-decoration: none;
}

.pager__link:hover svg path,
.pager__link:focus svg path,
.pager__link:active svg path {
  fill: #fff;
}

.pager__item.active+.pager__item,
.pager__item:hover+.pager__item {
  .pager__link {
    border-left-color: #f0c040;
  }
}








.search-filter {
  display: flex;
  justify-content: center;
  margin: 20px auto;
  background-color: #fff;
  max-width: 1180px;
  padding: 15px 10px;
  border-radius: 40px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
  flex-wrap: wrap;
}

.search-filter input[type="text"],
.search-filter select {
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-width: 200px;
}

.search-filter input[type="text"]::placeholder,
.search-filter select {
  color: #555;
}

.search-filter input[type="text"]:focus,
.search-filter select:focus {
  border-color: #000;
}

.search-filter input[type="text"]::placeholder {
  opacity: 0.5;
}

.inputWidth {
  width: 300px;
}







.add-user-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  background-color: #fff;
  max-width: 500px;
  margin: 40px auto;
  padding: 50px 20px;
  border-radius: 50px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}

.add-user-form h2 {
  margin: 20px;
}

.user-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
}

.input-field,
.select-field {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 100%;
}

.hr {
  width: 90%;
  height: 2px;
  background-color: #c0c0c0;
  margin-top: 20px;

}

.submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  background-color: #daa30a;
  color: #fff;
  cursor: pointer;
  width: 100%;
  font-size: 1.5rem;
}
.create-button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  background-color: rgb(9, 42, 188);
  color: #fff;
  cursor: pointer;
  width: 300px;
  font-size: 1.5rem;
}

.submit-button:hover {
  background-color: #eeb23a;
}
.create-button:hover {
  background-color: #3a79ee;
}
.teamAlert {
  color: rgb(15, 136, 53);
  font-size: smaller;
  text-align: center;
}





/* CSS for TeamTable */
table {
  width: 95%;
  margin: 30px auto 50px;
  border-collapse: collapse;
  border-collapse: separate;
  border-spacing: 5px;
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #ffffff;
  border-radius: 10px;
}
table th {
  background-color: #4CAF50;
  color: #fff;
  border-radius: 5px;
}

/* Style for View button */
button {
  background-color: #4CAF50;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

/* CSS for overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.overlay h2 {
  margin-top: 0;
}

.overlay p {
  margin-bottom: 10px;
}

.overlay button {
  background-color: #f44336; /* Red */
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  width: 100%;
}

.overlay button:hover {
  background-color: #d32f2f; /* Darker red on hover */
}

/* Responsive Styles */
@media screen and (max-width: 600px) {
  /* Adjust table layout for smaller screens */
  table th, table td {
    padding: 6px;
  }
  
  /* Make overlay content more compact */
  .overlay-content {
    padding: 10px;
  }
}

.card-buttons {
  display: flex;
  flex-direction: row;
}

.overlayBtn {
  margin: 10px;
  border-radius: 10px;
}